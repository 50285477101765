<template>
  <c-step
    stepperGrpCd="SELF_INSPECTION_RESULT"
    :currentStepCd="currentStatusCd"
    :param="popupParam"
    v-model="selfInspectionStatusCd"
    @currentStep="currentStep"
    @closePopup="closePopup"
    @stepClick="stepClick"
    >
  </c-step>
</template>

<script>
export default {
  name: 'compliance-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
        count: 0,
      }),
    },
  },
  data() {
    return {
      editable: true,
      selfInspectionStatusCd: '',
      currentStatusCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.setStep();
    },
    closePopup() {
      this.$emit('closePopup')
    },
    setStep() {
      if (!this.popupParam.selfInspectionStatusCd || this.popupParam.selfInspectionStatusCd === 'SISC000001') { // 등록시 또는 계획중
        this.selfInspectionStatusCd = 'SIR0000001'
        this.currentStatusCd = 'SIR0000001'
      } else if (this.popupParam.selfInspectionStatusCd === 'SISC000002') { // 계획완료
        this.selfInspectionStatusCd = 'SIR0000002'
        this.currentStatusCd = 'SIR0000003'
      } else if (this.popupParam.selfInspectionStatusCd === 'SISC000003') { // 결재중
        this.selfInspectionStatusCd = 'SIR0000002'
        this.currentStatusCd = 'SIR0000003'
      } else if (this.popupParam.selfInspectionStatusCd === 'SISC000004') { // 감사완료
        this.selfInspectionStatusCd = 'SIR0000004'
        this.currentStatusCd = 'SIR0000004'
      }
    },
    currentStep(step) {
      if (!step || step === 'SISC000001') {
        this.selfInspectionStatusCd = 'SIR0000001'
        this.currentStatusCd = 'SIR0000001'
      } else if (step === 'SISC000002') {
        this.selfInspectionStatusCd = 'SIR0000002'
        this.currentStatusCd = 'SIR0000003'
      } else if (step === 'SISC000003') {
        this.selfInspectionStatusCd = 'SIR0000002'
        this.currentStatusCd = 'SIR0000003'
      } else {
        this.selfInspectionStatusCd = 'SIR0000004'
        this.currentStatusCd = 'SIR0000004'
      }
      this.popupParam.selfInspectionStatusCd = step;
    },
    stepClick() {
      this.popupParam.count++;
    },
  }
};
</script>
